import "./src/styles/global.css"

import React from "react"
import { AnimatePresence } from "framer-motion"

/*
 * This helps enable page transitions when navigating to a new page.
 *
 * This is built based on this guide: https://janessagarrow.com/blog/gatsby-framer-motion-page-transitions/
 */
export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
)
